<template>
  <div class="relative" :style="{transform: `translateY(${centeredTopOffset}px)`}">
    <slot></slot>
    <!-- Slot for parent component -->
    <div
      v-if="showMessage && headline"
      ref="messageBox"
      :class="['absolute', position, msgClasses, 'max-w-sm', 'min-w-[100px]']"
    >
      <!-- Close Button -->
      <button
        :class="[
          'absolute top-4 right-4 text-opacity-50 hover:text-opacity-85',
          variation === 'dark' ? 'text-white' : 'text-black'
        ]"
        @click="closeMsgBox"
      >
        <font-awesome-icon :icon="faXmark" :size="'2x'" />
      </button>

      <!-- Headline Section -->
      <div class="font-bold px-3 py-3 mr-6">
        <h2 class="text-lg font-semibold">
          {{ headline }}
        </h2>
      </div>

      <!-- Body Section -->
      <div class="px-3 pt-0 pb-0">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <flow :gap="3" v-html="text" />
      </div>

      <!-- Call To Action (CTA) Button -->
      <div class="px-3 py-2 item-center justify-center">
        <standard-button
          v-if="buttonUrl"
          class="mt-2 mb-1"
          :color="buttonColor"
          :expand="true"
          :label="buttonText"
          :text="buttonText"
          @click="handleBtnClick"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, computed} from 'vue';
import Flow from '../../core/compositions/Flow.vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons/faXmark';
import StandardButton from '../../core/button/StandardButton.vue';
import {ButtonColor} from '../../core/button/implementation/utils';

const props = defineProps({
  variation: {type: String, default: 'primary'},
  headline: {type: String, default: ''},
  text: {type: String, default: ''},
  showMessage: {type: Boolean, default: true},
  position: {type: String, default: '-top-2 -right-2'},
  topMargin: {type: String, default: 'mt-fl-md-2xl'},
  centeredTopOffset: {type: Number, default: 0},
  buttonUrl: {type: String, default: null},
  buttonText: {type: String, default: null},
  buttonNewTab: {type: Boolean, default: false}
});

const emit = defineEmits(['close']);

const msgClasses = computed(() => {
  const baseClasses = `${props.topMargin} text-xs py-1 px-2 rounded-lg`;
  const variationClasses: Record<string, string> = {
    light: 'bg-gradient-to-br from-lightest to-light text-black border border-mid',
    primary: 'bg-gradient-to-br from-primary to-pp5 text-black',
    dark: 'bg-darkest text-white border border-dark'
  };
  return `${baseClasses} ${variationClasses[props.variation] || variationClasses.light}`;
});

const buttonColor = computed<ButtonColor>(() => {
  const colorMap: Record<string, ButtonColor> = {
    light: 'black',
    primary: 'black',
    dark: 'primary'
  };
  return colorMap[props.variation] || 'light';
});

const showMessage = ref(props.showMessage);
const closeMsgBox = () => {
  showMessage.value = false;
  emit('close'); // Emit close event and let parent handle it
};

const handleBtnClick = () => {
  if (props.buttonUrl && props.buttonNewTab) {
    window.open(props.buttonUrl, '_blank'); // New tab
  } else if (props.buttonUrl) {
    window.open(props.buttonUrl, '_self'); // Current tab
  }
};
</script>
