<template>
  <div :id="props.location">
    <content-column :width="contentWidth">
      <transition v-if="message" name="slide-fade" appear>
        <MessageBox
          v-if="showMessageBox && !isCenterPopup && messageId"
          :class="[showCenterMessage, 'z-20', props.centered ? 'centered-overlay-message' : '']"
          :variation="variation"
          :headline="messageHeading"
          :text="messageBody"
          :show-message="true"
          :button-text="ctaText"
          :button-url="ctaUrl"
          :button-new-tab="ctaNewTab"
          :position="position"
          :centered-top-offset="centeredTopOffset"
          :top-margin="topMargin"
          :images="message.imageCollection"
          @close="handleClose"
          @touchstart.passive="handleTouchStart"
          @touchmove.passive="handleTouchMove"
          @touchend="handleTouchEnd"
        />
      </transition>
      <MessageBoxPopup
        v-if="showMessageBox && isCenterPopup && messageId"
        :class="'z-[100] centered-message-container absolute'"
        :variation="'dark'"
        :headline="messageHeading"
        :text="messageBody"
        :show-message="true"
        :button-text="ctaText"
        :button-url="ctaUrl"
        :button-new-tab="ctaNewTab"
        :position="position"
        :top-margin="topMargin"
        :is-center-popup="isCenterPopup"
        :images="message.imageCollection"
        @close="handleClose"
        @touchstart.passive="handleTouchStart"
        @touchmove.passive="handleTouchMove"
        @touchend="handleTouchEnd"
      />
    </content-column>
  </div>
  <div ref="slotContent">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import {computed, PropType, ref, watchEffect} from 'vue';
import {StandardPageAreaWidth} from '../core/column-layout/utils';
import ContentColumn from '../core/compositions/ContentColumn.vue';
import focusMode from '../vue-composition/message/focus-mode';
import MessageBox from './components/MessageBox.vue';
import MessageBoxPopup from './components/MessageBoxPopup.vue';
import {
  initializeReadMessages,
  isMessageRead,
  setMessageAsRead,
  MessageComposition
} from '../vue-composition/message/message';
import {
  Message,
  MessageArray,
  MessageLocation,
  MessageBody,
  MessageHeading,
  MessageCallToActionBlock
} from '../../backend/message/message-types';
import {useTouchEvents} from '../vue-composition/touch/swipe-x';

const props = defineProps({
  location: {type: String as PropType<MessageLocation>, required: true},
  messages: {type: Object as PropType<Readonly<MessageComposition>>, required: true},
  variation: {type: String, default: 'light'},
  position: {type: String, default: ''},
  topMargin: {type: String, default: ''},
  contentWidth: {type: String as PropType<StandardPageAreaWidth>, default: 'wide'},
  isCenterPopup: {type: Boolean, default: false},
  centered: {type: Boolean, default: false},
  centeredTopOffset: {type: Number, default: 0}
});

let showMessageBox = ref(true);
const showCenterMessage = ref(props.isCenterPopup);

// Parsed message data
const messageId = ref('');
const messageHeading = ref('');
const messageBody = ref('');
const ctaText = ref('');
const ctaUrl = ref('');
const ctaNewTab = ref(false);

initializeReadMessages();

// Only show the first message for location
const message = computed(() => {
  const messages = props.messages.get(props.location) as MessageArray | undefined;
  return messages && messages.length > 0 ? messages[0] : ({} as Message);
});

const handleClose = () => {
  setMessageAsRead(message.value);
  showMessageBox.value = false;
};

// Close messages with touch swipe events
const {handleTouchStart, handleTouchMove, handleTouchEnd} = useTouchEvents(handleClose);

watchEffect(() => {
  const msg = message.value;
  showMessageBox.value = !isMessageRead(msg);

  // Check if focus mode is active
  if (focusMode.state.value) {
    showMessageBox.value = false;
    return;
  }

  if (msg && msg.message) {
    messageId.value = msg.id;

    const headingBlock = msg.message.find(
      block => block.typeHandle === 'messageHeading'
    ) as MessageHeading;
    messageHeading.value = headingBlock?.messageHeadingText || '';

    const bodyBlock = msg.message.find(block => block.typeHandle === 'messageBody') as MessageBody;
    messageBody.value = bodyBlock?.messageBodyParagraphs || '';

    const ctaTextBlock = msg.messageCallToAction.find(
      block => block.typeHandle === 'messageCta'
    ) as MessageCallToActionBlock;
    ctaText.value = ctaTextBlock?.messageCtaText || '';

    const ctaUrlBlock = msg.messageCallToAction.find(
      block => block.typeHandle === 'messageCta'
    ) as MessageCallToActionBlock;
    ctaUrl.value = ctaUrlBlock?.messageCtaUrl || '';

    const ctaNewTabBlock = msg.messageCallToAction.find(
      block => block.typeHandle === 'messageCta'
    ) as MessageCallToActionBlock;
    ctaNewTab.value = ctaNewTabBlock?.messageCtaOpenInNewTab || false;
  } else {
    // Reset if no message is found
    messageId.value = '';
    messageHeading.value = '';
    messageBody.value = '';
    ctaText.value = '';
    ctaUrl.value = '';
    ctaNewTab.value = false;
  }
});
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out; /* Time for slide-in */
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1); /* Time for slide-out */
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px); /* Slide in from right */
  opacity: 0;
}
.centered-message-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7); /* Dim background */
  transition: opacity 0.3s ease;
}
.centered-overlay-message {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
